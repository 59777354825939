<template lang="pug">
    div(class="w-full relative bg-grey-8 h-screen")
        div(class="absolute signup-box w-500")
            img(src="@/assets/images/QPA_standard_logo.png" class="m-auto w-150 mb-2")
            h1.text-black.text-center Survey Cloud Sign Up
                .mt-4.mb-2.text-left.bg-primary-lightest.px-4.py-2.text-xs.text-primary
                    fa-icon(icon="exclamation-circle" class="mr-2")
                    .inline Do you have a 
                    .inline.font-bold QPA connect 
                    .inline account?
                    .block Enter your Connect ID below. This will help prefill some details.

                .grid.grid-flow-col.grid-cols-2.gap-4
                    .col-span-1
                        v-text-field(
                            label="Enter Connect ID"
                            :rules="rules"
                            hide-details="auto"
                        )
                .grid.grid-cols-2.gap-4
                    .col-span-1
                        v-text-field(
                            v-model="email"
                            label="Email"
                            :rules="rules"
                            hide-details="auto"
                        )
                    .col-span-1
                        v-text-field(
                            label="Password"
                            :type="showPassword ? 'text' : 'password'"
                            :rules="rules"
                            hide-details="auto"
                        )
                            template(v-slot:append)
                                fa-icon(
                                    :icon="showPassword ? 'eye' : 'eye-slash'"
                                    class="mt-1"
                                    @click="onShowPassword()"
                                )
                .grid.grid-cols-4.gap-4
                   // .col-span-1
                   //     v-select(
                   //         hide-details
                   //         label="Title"
                   //         :rules="[required]"
                   //     )
                    .col-span-2
                        v-text-field(
                            label="First name"
                            :rules="rules"
                            hide-details="auto"
                        )
                    .col-span-2
                        v-text-field(
                            label="Last name"
                            :rules="rules"
                            hide-details="auto"
                        )
                //.grid.grid-cols-2.gap-4
                //    .col-span-1
                //        v-text-field(
                //            label="Position"
                //            :rules="rules"
                //            hide-details="auto"
                //        )
                //    .col-span-1
                //        v-text-field(
                //            label="Phone"
                //            :rules="rules"
                //            hide-details="auto"
                //        )
                .mt-4.mb-2.flex.items-center
                    v-checkbox(
                        v-model="subscribeToNewsletter"
                        hide-details="true"
                        class="mt-0 pt-0"
                        :ripple="false"
                    )
                        template(v-slot:label)
                            span.inline.text-sm.text-grey-1.font-normal Subscribe to our newsletter
                .flex.items-center
                    v-checkbox(
                        v-model="accpetedTermsAndConditions"
                        hide-details="true"
                        class="mt-0 pt-0"
                        :ripple="false"
                    )
                        template(v-slot:label)
                            span.inline.text-sm.text-grey-1.font-normal I accept the 
                                a(
                                    target="_blank"
                                    class="inline font-bold"
                                ) Terms & Conditions of Use

                v-alert(
                    v-if="showAlert"
                    type="error"
                    class="text-sm mt-5"
                ) {{ alertMessage }}
                .mt-4.flex.justify-center
                    v-btn(
                        class="m-auto w-full"
                        depressed
                        color="success"
                        raised
                        :class="{'disabled': loading}"
                        @click.stop="onSignUp"
                    )
                        | {{signUpBtnText}}

</template>

<script>
import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api'
import { useMutation } from '@vue/apollo-composable'
import { INSERT_TENANT } from '@/graphql/mutations/insertTenant.gql'
import { INSERT_USER } from '@/graphql/mutations/insertUser.gql'
import { DELETE_TENANT } from '@/graphql/mutations/deleteTenant.gql'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { useRecaptcha } from '@/composables/useRecaptcha'

export default {
    name: 'Signup',
    setup(_, { root }) {
        const email = ref('')
        const subscribeToNewsletter = ref(false)
        const accpetedTermsAndConditions = ref(false)
        const tenantId = ref('')
        const showAlert = ref(false)
        const alertMessage = ref('')
        const showPassword = ref(false)
        const rules = ref([])
        const loading = ref(false)
        const { token, showBadge, hideBadge, loadRecaptcha, getRecaptchaToken, configureRecaptcha } = useRecaptcha({
            recaptchaLoaded: root.$recaptchaLoaded
        })
        const router = root.$router
        const http = root.$http

        // life cycle composables
        onMounted(async () => {
            await loadRecaptcha()

            configureRecaptcha(root.$recaptchaInstance, root.$recaptcha)
            showBadge()
        })

        onUnmounted(() => {
            hideBadge()
        })

        // methods
        const onShowPassword = () => {
            showPassword.value = !showPassword.value
        }

        const onSignUp = async () => {
            loading.value = true
            showAlert.value = false

            // TODO: uncomment below when fastapi recaptcha has been added, for now we are just calling create tenant
            let response
            try {
                await getRecaptchaToken('signup')
            } catch(e) {
                showAlert.value = true
                alertMessage.value = 'Something went wrong, please try again later or contact your systems administrator'
                loading.value = false
                return
            }

            if (!token.value) return
            try {
                response = await validate({token: token.value})
            } catch(e) {
                showAlert.value = true
                alertMessage.value = 'Something went wrong, please try again later or contact your systems administrator'
                loading.value = false
                return
            }

            if (response) await insertTenant()
        }

        // apollo calls to hasura
        const {
            onError: onTenantCreateError,
            mutate: insertTenant,
            onDone: onTenantCreated
        } = useMutation(INSERT_TENANT,
            () => {
                return {
                    variables: {
                        tenant: {
                            name: email.value
                        }
                    }
                }
            }
        )
        const {
            onError: onUserCreateError,
            mutate: insertUser,
            onDone: onUserCreated
        } = useMutation(INSERT_USER)
        const {
            onError: onDeleteTenantError,
            mutate: deleteTenant,
            onDone: onTenantDeleted
        } = useMutation(DELETE_TENANT)

        onTenantCreated(({ data }) => {
            const tenant = camelcaseKeys(data.tenant, {deep: true})
            tenantId.value = tenant.tenantId

            if (tenant.tenantId === '') {
                showAlert.value = true
                alertMessage.value = 'Error occurred while creating tenant.'
                return
            }

            console.log('New tenant is created successfully')
            insertUser(snakecaseKeys({
                user: {
                    tenantId: tenant.tenantId,
                    email: email.value,
                    subscribeToNewsletter: subscribeToNewsletter.value,
                    accpetedTermsAndConditions: accpetedTermsAndConditions.value,
                    role: 'tenant_admin'
                }
            }, {deep: true}))
        })
        onUserCreated(({ data }) => {
            console.log('New user is created successfully')
            router.push({name: 'ProfileSettings', query: {t: tenantId.value}})
        })

        onTenantDeleted(({ data }) => {
            console.log('The tenant is deleted')
            // need to stop loading here in case user create fails and we succeed at this point
            loading.value = false
        })

        onTenantCreateError(error => {
            showAlert.value = true
            alertMessage.value = 'Error occurred while creating tenant.'

            console.log('Error occurred while creating tenant.')
            loading.value = false
        })

        onUserCreateError(error => {
            showAlert.value = true
            alertMessage.value = 'Error occurred while creating user.'
            
            console.log('Error occurred while creating user.')
            deleteTenant({
                tenantId: tenantId.value
            })
        })

        onDeleteTenantError(error => {
            // need to make sure we stop loading here otherwise the user may not ever get the button back
            loading.value = false
        })

        // recaptcha

        const validate = (params) => {
            return http.post('/verify-site', params)
                .then((response) => {
                    if (response.status !== 200) Promise.reject('Something went wrong')
                    else return true
                })
                .catch(error => {
                    console.log('error from recaptcha', error)
                    alertMessage.value = 'Could not create your account at this time, please try again in a few minutes'
                    showAlert.value = true
                })
        }

        // computed properties
        const signUpBtnText = computed(() => {
            return loading.value ? 'Please wait...' : 'Sign Up'
        })

        return {
            email,
            subscribeToNewsletter,
            accpetedTermsAndConditions,
            showAlert,
            alertMessage,
            showPassword,
            rules,
            signUpBtnText,
            loading,
            onShowPassword,
            onSignUp
        }
    }
}
</script>
