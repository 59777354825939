import { ref, watch } from '@vue/composition-api'

export function useRecaptcha(variables) {
    const recaptchaInstance = ref(null)
    const recaptchaLoaded = ref(null)
    const recaptcha = ref(null)
    const token = ref(null)

    const showBadge = () => {
        if (recaptchaInstance.value) recaptchaInstance.value.showBadge()
    }

    const hideBadge = () => {
        if (recaptchaInstance.value) recaptchaInstance.value.hideBadge()
    }

    const loadRecaptcha = async () => await recaptchaLoaded.value?.()
    const getRecaptchaToken = async (action) => {
        const isLoaded = await recaptchaLoaded.value()

        // Execute reCAPTCHA with action "login".
        if (!isLoaded) return
        token.value = await recaptcha.value(action)
    }

    const configureRecaptcha = (newRecaptchaInsance, recaptchaInstance) => {
        recaptchaInstance.value = newRecaptchaInsance
        recaptcha.value = recaptchaInstance
    }

    const validate = (params) => {
        return http.post('/verify-site', params)
            .then((response) => {
                if (response.status !== 200) Promise.reject('Something went wrong')
                else return true
            })
            .catch(error => {
                console.log('error from recaptcha', error)
                alertMessage.value = 'Could not create your account at this time, please try again in a few minutes'
                showAlert.value = true
            })
    }

    watch(() => variables.recaptchaLoaded, (newInstance) => {
        recaptchaLoaded.value = newInstance
    })

    return { token, showBadge, hideBadge, loadRecaptcha, getRecaptchaToken, configureRecaptcha }
}
