import gql from 'graphql-tag'

const INSERT_TENANT = gql`
mutation insertTenant(
    $tenant: form_builder_hasura_tenant_insert_input!,
    $on_conflict: form_builder_hasura_tenant_on_conflict = {
        constraint: tenant_pkey,
        update_columns: [name, logo, auth0_organization_id, short_name, status]
    }
) {
    tenant: insert_form_builder_hasura_tenant_one(
        object: $tenant,
        on_conflict: $on_conflict
    ) {
          tenant_id
          subscription_id
          status
          short_name
          parent_tenant_id
          name
          logo
          auth0_organization_id
    }
}
`

export {
    INSERT_TENANT
}
