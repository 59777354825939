import gql from 'graphql-tag'

const INSERT_USER = gql`mutation insertUser(
    $user: form_builder_hasura_user_insert_input!,
    $on_conflict: form_builder_hasura_user_on_conflict = {constraint: user_pkey, update_columns: [tenant_id, email, subscribe_to_newsletter, accpeted_terms_and_conditions]}
) {
    user: insert_form_builder_hasura_user_one(
        object: $user,
        on_conflict: $on_conflict
    ) {
        accpeted_terms_and_conditions
        email
        first_name
        last_name
        tenant {
            name
            tenant_id
        }
        user_id
        subscribe_to_newsletter
        role
    }
}
`

export {
    INSERT_USER
}
