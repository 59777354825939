<template lang="pug">
    .w-full.relative.bg-grey-8.h-screen
        .absolute.signup-box.w-500
            img(src="@/assets/images/QPA_standard_logo.png" class="m-auto w-150 mb-2 mt-8")
            h1.text-primary.text-center.py-4 {{ practiceName }}
            h3.text-black.text-center Practice Survey Invite

            v-form(
                ref="practiceInviteForm"
                v-model="isValid"
                @submit.prevent="formSubmitHandler"
            )
                v-text-field(
                    v-model="email"
                    label="Email"
                    hide-details outlined flat dense
                    class="mx-5 mt-8"
                    :rules="[required, emailValidator]"
                )
                v-textarea(
                    v-model="instructionText"
                    label="Instruction"
                    hide-details outlined flat dense
                    class="mx-5 mt-8"
                )
                v-alert(
                    v-if="showAlert && !invitePracticeLoading"
                    :type="type"
                    class="text-sm mx-5 mt-8 mb-0"
                ) {{ alertMessage }}
                .text-center
                    v-btn(
                        type="submit"
                        color="success"
                        class="w-200 mt-8 mb-8"
                        :class="invitePracticeLoading ? 'pointer-events-none' : ''"
                    )
                        fa-icon(v-if="invitePracticeLoading" icon="spinner" spin class="mr-2")
                        | {{ btnText }}

</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@/modules/formRules'
import { INVITE_PRACTICE } from '@/graphql/mutations/invitePractice.gql'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { GET_TENANT_BY_SHORT_NAME } from '@/graphql/queries/getTenantByShortName.gql'
import BackLink from '@/components/shared/BackLink'
import camelcaseKeys from 'camelcase-keys'

export default {
    name: 'PracticeInvite',
    components: {BackLink},
    setup(_, { root }) {
        const practiceName = ref('')
        const instructionText = ref('')
        const email = ref('')
        const isValid = ref(false)
        const practiceInviteForm = ref(null)
        const goBackText = ref('Back to dashboard')
        const backRoute = ref({
            name: 'Dashboard'
        })
        const showAlert = ref(false)
        const type = ref('')
        const alertMessage = ref('')

        const route = computed(() => root.$route)
        const params = computed(() => {
            return {
                shortName: route.value.params?.shortName
            }
        })

        const formSubmitHandler = () => {
            showAlert.value = false
            isValid.value = practiceInviteForm.value.validate()
            if (!isValid.value) return

            invitePractice()
        }

        const {
            onError: onInivitePracticeError,
            mutate: invitePractice,
            onDone: onPracticeInvited,
            loading: invitePracticeLoading
        } = useMutation(INVITE_PRACTICE, () => {
                return {
                    variables: {
                        instruction: instructionText.value,
                        email: email.value,
                        shortName: route.value.params?.shortName
                    }
                }
            }
        )
        onPracticeInvited((data) => {
            showAlert.value = true
            type.value ='success'
            alertMessage.value ='The invitation email was sent successfully.'
        })
        onInivitePracticeError((error) => {
            showAlert.value = true
            type.value = 'error'
            alertMessage.value = 'Could not invite Practice.'
        })

        const {
            onResult: getTenantOnResult,
            onError: getTenantOnError,
            load: loadTenant
        } = useLazyQuery(GET_TENANT_BY_SHORT_NAME, params)
        getTenantOnResult(({ data }) => {
            const tenant = camelcaseKeys(data?.tenant, {deep:true})
            practiceName.value = tenant[0]?.name
        })
        getTenantOnError((error) => {
            showAlert.value = true
            type.value ='error'
            alertMessage.value ='Could not load tenant, please try reloading the page.'
        })

        // #region computed
        const btnText = computed(() => {
            return invitePracticeLoading.value ? 'Please wait...' : 'Send'
        })
        // #endregion

        watch(() => invitePracticeLoading.value, (value) => {
            if (value) {
                showAlert.value = false
                type.value =''
                alertMessage.value =''
            }
        })

        loadTenant()

        return {
            practiceName,
            instructionText,
            email,
            isValid,
            practiceInviteForm,
            invitePracticeLoading,
            goBackText,
            backRoute,
            showAlert,
            type,
            alertMessage,
            btnText,
            required,
            emailValidator,
            formSubmitHandler
        }
    }
}
</script>
