import gql from 'graphql-tag'

const DELETE_TENANT = gql`mutation deleteTenant($tenantId: uuid!) {
    tenant: delete_form_builder_hasura_tenant_by_pk(tenant_id: $tenantId) {
        tenant_id
        name
    }
}
`

export {
    DELETE_TENANT
}
